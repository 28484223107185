<template>
    <div class="question-item">
        <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
        <div :style="{ color: pageInfo.style.optionColor }">
            <div class="checkbox" v-for="(option, index) in options" :key="index">
                <Checkbox disabled>{{option}}</Checkbox>
            </div>
            <div>
                <Checkbox v-if="other" label="其他" disabled>
                    <span>其他</span>
                    <Input disabled style="width: 200px;" />
                </Checkbox>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MultipleChoice",
    props: ["title",  "required", "options", "other", "pageInfo"]
}
</script>
<style lang="less" scoped>
    .checkbox {
        margin-bottom: 10px;
    }
</style>